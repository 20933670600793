<template>
    <div class="w-auto h-full bg-white p-[2rem] font-Inter px-6 py-11" id="ViewCount">
        <div class="flex flex-row justify-between">
            <p class="text-[2rem] font-black text-[#2B3133] tracking-[0px]">접속 인원</p>
        </div>
        <div class="flex pl-11 float-right">
            <select class="w-64 border-[1px] text-left pl-[1rem] h-[3rem] mr-[2rem] rounded-lg border-[#C7C7C7]"
                v-model="term1" @change="changeTerm1($event)">
                <option v-for="t in termList1" :key="t">{{ t }}</option>
            </select>
            <select class="w-64 border-[1px] text-left pl-[1rem] h-[3rem] rounded-lg border-[#C7C7C7]" v-model="term2"
                @change="changeTerm2($event)">
                <option v-for="t in termList2" :key="t">{{ t }}</option>
            </select>
        </div>
        <div class="chart_wrapper flex justify-between">
            <div class="flex flex-row h-full mt-[45px]">
                <div class="flex flex-col">
                    <DxDataGrid id="OmGrid" ref="dataGrid" :class="``" :height="`390px`" :allow-column-resizing="true"
                        :width="`300px`" :column-resizing-mode="'currentMode'" :data-source="dataSource"
                        :show-borders="true">
                        <DxPaging :enabled="false" />
                        <DxSelection mode="single" />
                        <DxSearchPanel :visible="false" :width="240" placeholder="Search..." />
                        <DxEditing mode="popup" :allow-updating="false" :allow-adding="true" :allow-deleting="false">
                        </DxEditing>
                        <DxHeaderFilter :visible="true" />
                        <DxScrolling mode="'virtual" />
                        <DxLoadPanel :enabled="true" />
                        <DxColumn caption="날짜" alignment="center" data-field="RDATE" />
                        <DxColumn caption="접속인원" alignment="center" data-field="COUNT" />
                        <DxSummary>
                            <DxTotalItem column="COUNT" summary-type="sum" />
                        </DxSummary>
                    </DxDataGrid>
                </div>
            </div>
            <div class="graph_wrapper2">
                <div id="chart_visitCount">
                    <DxChart id="chart" :data-source="dataSource" palette="Violet" width="1350px">
                        <DxCommonSeriesSettings type="line" argument-field="RDATE" />
                        <DxSeries v-for="t in xAixsSources" :key="t.value" :value-field="t.value" :name="t.name" />
                        <DxMargin :bottom="60" />
                        <DxArgumentAxis :value-margins-enabled="false" discrete-axis-division-mode="crossLabels">
                            <DxGrid :visible="true" />
                        </DxArgumentAxis>
                        <DxLegend vertical-alignment="bottom" horizontal-alignment="center"
                            item-text-position="bottom" />
                        <DxExport :enabled="true" />
                        <DxTooltip :enabled="true" />
                    </DxChart>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxForm,
    DxScrolling,
    DxToolbar,
    DxSelection,
    DxLoadPanel,
    DxKeyboardNavigation,
    DxSummary,
    DxTotalItem
} from 'devextreme-vue/data-grid';
import { DxPopup } from 'devextreme-vue/popup';
import {
    DxBarGauge, DxLabel, DxFont, DxSize
} from 'devextreme-vue/bar-gauge';
import {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxSubtitle,
    DxExport,
    DxMargin,
    DxLegend,
    DxTitle,
    DxTooltip,
    DxGrid
} from 'devextreme-vue/chart';
import DataHelper from '../Helpers/DataHelper';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxPopup,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxForm,
        DxScrolling,
        DxToolbar,
        DxSelection,
        DxLoadPanel,
        DxBarGauge, DxKeyboardNavigation,
        DxLabel, DxExport, DxTitle,
        DxFont, DxSize, DxMargin, DxLegend, DxTooltip,

        DxChart,
        DxCommonSeriesSettings,
        DxMargin,
        DxArgumentAxis,
        DxTitle,
        DxSubtitle,
        DxSeries,
        DxGrid,
        DxSummary,
        DxTotalItem
    },
    props: {
        type: String,
    },
    computed: {
        dataGrid() {
            return this.$refs.dataGrid.instance;
        },
    },
    data() {
        return {
            dataSource: [],
            data: [],
            xAixsSources: [{ value: 'COUNT', name: '접속인원' }],
            termList1: ['2024', '2025', '2026'],
            termList2: ['전체', '1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월',],
            term1: '',
            term2: '',
            today: new Date(),
            today_year:'',
            today_month:''
        }
    },
    watch: {
    },
    async mounted() {
        this.today_year = this.today.getFullYear();
        this.today_month = this.today.getMonth() + 1;
        this.term1 = this.today_year;
        this.term2 = this.today_month + '월';
        await this.initData();
    },
    methods: {
        async initData() {
            let parameters = []; //초기값은 현재 년도, 전체
            let param = {}; //하나의 쌍
            param["START_DATE"] = '';
            param["END_DATE"] = '';
            param["YEAR"] = this.term1; //초기값 올해 년도
            param["MONTH"] = this.term2.slice(0,-1).toString().padStart(2, '0');
            parameters.push(param);
            this.dataSource = [];
            this.dataSource = await DataHelper.InitVisitCountMonth(parameters);
            this.dataGrid.repaint();
        },
        async changeTerm1(event) {
            this.term2 = '전월';
            let parameters = [];
            let param = {};
            param["START_DATE"] = '';
            param["END_DATE"] = '';
            param["YEAR"] = this.term1;

            parameters.push(param);
            this.dataSource = await DataHelper.InitVisitCount(parameters);
            this.dataGrid.repaint();
        },
        async changeTerm2(event) {
            let value;
            let parameters = [];
            let param = {};
            param["START_DATE"] = '';
            param["END_DATE"] = '';
            value = this.term2.slice(0, -1);
            param["YEAR"] = this.term1;
            if (value == '전') {
                parameters.push(param);
                console.log(parameters);
                this.dataSource = await DataHelper.InitVisitCount(parameters);
            } else {
                param["MONTH"] = value.padStart(2,'0');
                parameters.push(param);
                this.dataSource = await DataHelper.InitVisitCountMonth(parameters);
                this.dataGrid.repaint();
            }

        }
    }
}
</script>

<style></style>