<template>
    <div class="w-auto h-full bg-white p-[2rem] font-Inter px-6 py-11">
        <div class="flex flex-row justify-between">
            <p class="text-[2rem] font-black text-[#2B3133] tracking-[0px]">애드인 다운로드</p>
        </div>
        <div class="flex pl-11 float-right">
            <select class="w-64 border-[1px] text-left pl-[1rem] h-[3rem] mr-[2rem] rounded-lg border-[#C7C7C7]"
                v-model="term1" @change="changeTerm1($event)">
                <option v-for="t in termList1" :key="t">{{ t }}</option>
            </select>
            <select class="w-64 border-[1px] text-left pl-[1rem] h-[3rem] mr-[2rem] rounded-lg border-[#C7C7C7]"
                v-model="term2" @change="changeTerm2($event)">
                <option v-for="t in termList2" :key="t">{{ t }}</option>
            </select>
            <select class="w-64 border-[1px] text-left pl-[1rem] h-[3rem] rounded-lg border-[#C7C7C7]" v-model="term3"
                @change="changeTerm3($event)">
                <option v-for="t in termList3" :key="t">{{ t }}</option>
            </select>
        </div>
        <div class="graph_wrapper">
            <div id="chart_executeCount">
                <div class="trend_line pt-[1rem]" v-if="this.term3 == this.termList3[0]">
                    <p class="mr-[1rem] ml-[1rem]">누적 집계</p>
                    <InputSwitch v-model="trendSwitch" @update:modelValue="onChangeTrend($event)" />
                </div>
                <div class="time_calendar pt-[1rem]"  v-if="this.term3 == this.termList3[2]">
                    <p class="font-semibold ml-[1rem] mr-[1rem] cursor-pointer" @click="prevDate">&lt;</p>
                    <input type="date" v-model="timeDate" @change="dateChange">
                    <p class="font-semibold ml-[1rem] mr-[1rem] cursor-pointer" @click="nextDate">&gt;</p>
                </div>
                <DxChart class="h-[370px]" id="chart" :data-source="dataSource_chart" palette="Material" @legend-click="onLegendClick"
                    @point-click="onPointClick">
                    <DxCommonSeriesSettings type="line" argument-field="RDATE" />
                    <DxSeries v-for="t in itemSource" :key="t.value" :value-field="t.value" :name="t.name" :visible="t.visible"/>
                    <DxMargin :bottom="0" />
                    <DxArgumentAxis :value-margins-enabled="false" discrete-axis-division-mode="crossLabels">
                    </DxArgumentAxis>
                    <DxLegend vertical-alignment="bottom" horizontal-alignment="center" item-text-position="bottom" />
                    <DxExport :enabled="true" />
                    <DxTooltip :enabled="true" />
                </DxChart>
            </div>
            <DxButton class="float-right  mr-[1rem]"
              :width="`80px`"
              :text="buttonText"
              type="normal"
              styling-mode="contained"
              @click="refresh"
              id="OnOffButton"
            />
        </div>
        <div class="flex flex-row h-full justify-between">
            <div class="flex flex-col">
                <DxDataGrid id="OmGrid" ref="dataGrid" :class="``" :height="`auto`" :allow-column-resizing="true"
                    width="100%" :column-resizing-mode="'currentMode'" :data-source="dataSource" :show-borders="true">
                    <DxPaging :enabled="false" />
                    <DxSelection mode="single" />
                    <DxEditing mode="popup" :allow-updating="false" :allow-adding="true" :allow-deleting="false">
                    </DxEditing>
                    <DxHeaderFilter :visible="true" />
                    <DxScrolling mode="'virtual" />
                    <DxLoadPanel :enabled="true" />
                    <DxColumn caption="번호" data-field="rownum" alignment="center" width="100px" />
                    <DxColumn caption="애드인명" data-field="AddinName" alignment="center" width="240px" />
                    <DxColumn caption="다운로드 수" data-field="InstallCount" alignment="center" width="240px" />
                </DxDataGrid>
            </div>
            <!-- <div class="bar_container">
                <DxChart id="bar_chart" :data-source="dataSource">
                    <DxSeries argument-field="CommandName" value-field="ExecuteCount" type="bar" color="#ffaa66" name="기능명"/>
                </DxChart>
            </div> -->
        </div>
    </div>
</template>

<script>
import DxButton, { DxButtonTypes } from 'devextreme-vue/button';
import InputSwitch from 'primevue/inputswitch';
import dayjs from 'dayjs';
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxForm,
    DxScrolling,
    DxToolbar,
    DxSelection,
    DxLoadPanel,
    DxKeyboardNavigation
} from 'devextreme-vue/data-grid';
import { DxPopup } from 'devextreme-vue/popup';
import {
    DxBarGauge, DxLabel,DxFont, DxSize
} from 'devextreme-vue/bar-gauge';
import {
  DxChart,
  DxSeries,
  DxArgumentAxis,
  DxCommonSeriesSettings,
  DxExport,
  DxMargin,
  DxLegend, 
  DxTooltip,
  DxGrid,
  DxTitle,
  DxSubtitle
} from 'devextreme-vue/chart';
import DataHelper from '../Helpers/DataHelper';

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxPopup,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxForm,
        DxScrolling,
        DxToolbar,
        DxSelection,
        DxLoadPanel,
        DxBarGauge, DxKeyboardNavigation,
        DxLabel, DxExport, DxTitle,
        DxFont, DxSize, DxMargin, DxLegend, DxTooltip,

        DxChart,
        DxCommonSeriesSettings,
        DxMargin,
        DxArgumentAxis,
        DxSubtitle,
        DxSeries,
        DxGrid,
        InputSwitch,
        DxButton
    },
    props: {
        type: String,
    },
    computed: {
        dataGrid() {
            return this.$refs.dataGrid.instance;
        },
    },
    data() {
        return {
            dataSource: [],
            dataSource_chart: [],
            data: [],
            itemSource : [],
            termList1: ['2024', '2025', '2026'],
            termList2: ['전체', '1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월',],
            termList3: ['기본', '시간대(전체)', '시간대(일별)'],
            term1: '',
            term2: '',
            term3: '',
            today: new Date(),
            today_year:'',
            today_month:'',
            trendSwitch: false,
            timeDate: '',
            todayDate: '',
            buttonText: 'OFF',
            countArray: ''
        }
    },
    watch: {
    },
    async mounted() {
        this.countArray = this.itemSource.length;
        this.today_year = this.today.getFullYear();
        this.today_month = this.today.getMonth() + 1;
        this.term1 = this.today_year;
        this.term2 = this.today_month + '월';
        this.term3 = '기본';
        this.todayDate = this.today_year + '-' + String(this.today.getMonth() + 1).padStart(2, '0') + '-' + String(this.today.getDate()).padStart(2, '0');
        this.timeDate = this.todayDate;
        await this.initData();
    },
    methods: {
        async initData() {
            let parameters = []; //초기값은 현재 년도, 전체
            let param = {}; //하나의 쌍
            let dataSourceInit = [];
            let tempRownum = 1;
            param["START_DATE"] = '';
            param["END_DATE"] = '';
            param["YEAR"] = this.term1; //초기값 올해 년도
            param["MONTH"] = this.term2.slice(0,-1).toString().padStart(2, '0');
            param["TREND"] = this.trendSwitch == true ? 'TRUE' : 'FALSE';
            param["DATE"] = this.timeDate;
            parameters.push(param);
            this.dataSource = [];
            this.dataSource = await DataHelper.InitAddinDownloadCountMonth(parameters);
            this.dataSource_chart = await DataHelper.InitAddinDownloadChartMonth(parameters);
            for (var i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i]["rownum"] = tempRownum++;
            }
            dataSourceInit = await DataHelper.InitAddinDownloadCountInit();
            for(var i = 0;i<this.dataSource.length; i++) {
                this.itemSource[i] = { value: dataSourceInit[i]["AddinName"], name: dataSourceInit[i]["AddinName"]};
            }
            console.log(parameters);
            console.log(this.dataSource_chart);
            this.dataGrid.repaint();
            
        },
        async changeTerm1(event) {
            this.term2 = '전체';
            this.term3 = '기본';
            let parameters = [];
            let param = {};
            let tempRownum = 1;
            param["START_DATE"] = '';
            param["END_DATE"] = '';
            param["YEAR"] = this.term1;
            param["TREND"] = 'FALSE';
            param["DATE"] = this.timeDate;
            parameters.push(param);
            this.dataSource_chart = await DataHelper.InitAddinDownloadChart(parameters);
            this.dataSource = await DataHelper.InitAddinDownloadCount(parameters);
            for (var i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i]["rownum"] = tempRownum++;
            }
            this.dataGrid.repaint();
        },
        async changeTerm2(event) {
            let value;
            let parameters = [];
            let param = {};
            let tempRownum = 1;
            param["START_DATE"] = '';
            param["END_DATE"] = '';
            value = this.term2.slice(0, -1);
            param["YEAR"] = this.term1;
            param["TREND"] = this.trendSwitch == true ? 'TRUE' : 'FALSE';
            if (value == '전') {
                param["MONTH"] = '전';
                parameters.push(param);
                if(this.term3 == this.termList3[0]) { //전체월, 기본이면
                    this.dataSource_chart = await DataHelper.InitAddinDownloadChart(parameters);
                    this.dataSource = await DataHelper.InitAddinDownloadCount(parameters);
                }
                else if (this.term3 == this.termList3[1]) { //전체월, 시간대(전체)
                    this.dataSource_chart = await DataHelper.InitAddinDownloadChart_time(parameters);
                    this.dataSource = await DataHelper.InitAddinDownloadCount(parameters);
                } else {
                    this.dataSource_chart = await DataHelper.InitAddinDownloadChart_datetime(parameters);
                    this.dataSource = await DataHelper.InitAddinDownloadCount_date(parameters);
                }
            } else {
                param["MONTH"] = value.padStart(2,'0');
                param["DATE"] = this.today_month == value ? this.todayDate : this.term1 + '-' + value.padStart(2, '0') + '-01'; //선택된 게 이번달이면 오늘, 아니면 매달 1일
                this.timeDate = param["DATE"];
                parameters.push(param);
                if(this.term3 == this.termList3[0]) { //해당월, 기본이면
                    this.dataSource_chart = await DataHelper.InitAddinDownloadChartMonth(parameters);
                    this.dataSource = await DataHelper.InitAddinDownloadCountMonth(parameters);
                }
                else if(this.term3 == this.termList3[1]) { //해당월, 시간대(전체)
                    this.dataSource_chart = await DataHelper.InitAddinDownloadChart_time(parameters);
                    this.dataSource = await DataHelper.InitAddinDownloadCountMonth(parameters);
                } else {
                    this.dataSource_chart = await DataHelper.InitAddinDownloadChart_datetime(parameters);
                    this.dataSource = await DataHelper.InitAddinDownloadCount_date(parameters);
                }
            }
            for (var i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i]["rownum"] = tempRownum++;
            }

        },
        async changeTerm3(event) {
            let value;
            let parameters = [];
            let param = {};
            let tempRownum = 1;
            param["START_DATE"] = '';
            param["END_DATE"] = '';
            value = this.term2.slice(0, -1);
            param["YEAR"] = this.term1;
            param["TREND"] = this.trendSwitch == true ? 'TRUE' : 'FALSE';
            param["DATE"] = this.timeDate;
            if (value == '전') {
                param["MONTH"] = '전';
                parameters.push(param);
                if(this.term3 == this.termList3[0]) { //전체월, 기본이면
                    this.dataSource_chart = await DataHelper.InitAddinDownloadChart(parameters);
                    this.dataSource = await DataHelper.InitAddinDownloadCount(parameters);
                }
                else if(this.term3 == this.termList3[1]) { //전체월, 시간대(전체)
                    this.dataSource_chart = await DataHelper.InitAddinDownloadChart_time(parameters);
                    this.dataSource = await DataHelper.InitAddinDownloadCount(parameters);
                } else {
                    this.dataSource_chart = await DataHelper.InitAddinDownloadChart_datetime(parameters);
                    this.dataSource = await DataHelper.InitAddinDownloadCount_date(parameters);
                }
            } else {
                param["MONTH"] = value.padStart(2,'0');
                param["DATE"] = this.today_month == value ? this.todayDate : this.term1 + '-' + value.padStart(2, '0') + '-01'; //선택된 게 이번달이면 오늘, 아니면 매달 1일
                this.timeDate = param["DATE"];
                parameters.push(param);
                if(this.term3 == this.termList3[0]) { //해당월, 기본이면
                    this.dataSource_chart = await DataHelper.InitAddinDownloadChartMonth(parameters);
                    this.dataSource = await DataHelper.InitAddinDownloadCountMonth(parameters);
                }
                else if(this.term3 == this.termList3[1]) { //해당월, 시간대(전체)
                    this.dataSource_chart = await DataHelper.InitAddinDownloadChart_time(parameters);
                    this.dataSource = await DataHelper.InitAddinDownloadCountMonth(parameters);
                } else {
                    this.dataSource_chart = await DataHelper.InitAddinDownloadChart_datetime(parameters);
                    this.dataSource = await DataHelper.InitAddinDownloadCount_date(parameters);
                }
            }
            for (var i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i]["rownum"] = tempRownum++;
            }
        },
        // onLegendClick(e) {
        //     const series = e.target;
        //     if (series.isVisible()) {
        //         series.hide();
        //         this.countArray--;
        //     } else {
        //         series.show();
        //         this.countArray++;
        //     }
        //     if(this.countArray == this.itemSource.length) {
        //         this.buttonText = "OFF";
        //         this.itemSource.forEach(t => {
        //              t.visible = true;
        //          })
        //     }
        //     if(this.countArray == 0) {
        //         this.buttonText = "ON";
        //         this.itemSource.forEach(t => {
        //              t.visible = false;
        //          })
        //     }
        // },
        onLegendClick(e) {
            const series = e.target;
            if (series.isVisible()) {
                series.hide();
            } else {
                series.show();
            }
        },
        onPointClick(e) {
            const series = e.target.series;
            if (series.isVisible()) {
                series.hide();
            }
        },
        async onChangeTrend(e) {
            let value;
            let parameters = [];
            let param = {};
            let tempRownum = 1;
            param["START_DATE"] = '';
            param["END_DATE"] = '';
            value = this.term2.slice(0, -1);
            param["YEAR"] = this.term1;
            if (value == '전') {
                param["MONTH"] = '전';
                param["TREND"] = this.trendSwitch == true ? 'TRUE' : 'FALSE'
                parameters.push(param);//전체월, 기본이면
                this.dataSource_chart = await DataHelper.InitAddinDownloadChart(parameters);
                //this.dataSource = await DataHelper.InitAddinExecuteCount(parameters);
            }
            else {
                param["MONTH"] = value.padStart(2, '0');
                param["TREND"] = this.trendSwitch == true ? 'TRUE' : 'FALSE'
                parameters.push(param);
                this.dataSource_chart = await DataHelper.InitAddinDownloadChartMonth(parameters);
            }
            for (var i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i]["rownum"] = tempRownum++;
            }
        },
        async dateChange() {
            let parameters = [];
            let param = {};
            let tempRownum = 1;
            param["START_DATE"] = '';
            param["END_DATE"] = '';
            param["YEAR"] = '';
            param["MONTH"] = '';
            param["TREND"] = '';
            param["DATE"] = this.timeDate;
            parameters.push(param);
            this.dataSource_chart = await DataHelper.InitAddinDownloadChart_datetime(parameters);
            this.dataSource = await DataHelper.InitAddinDownloadCount_date(parameters);

            for (var i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i]["rownum"] = tempRownum++;
            }
        },
        async prevDate() {
            const prevDate = dayjs(this.timeDate).subtract(1,'day').format('YYYY-MM-DD');
            let parameters = [];
            let param = {};
            let tempRownum = 1;
            this.timeDate = prevDate;
            param["START_DATE"] = '';
            param["END_DATE"] = '';
            param["YEAR"] = '';
            param["MONTH"] = '';
            param["TREND"] = '';
            param["DATE"] = this.timeDate;
            parameters.push(param);
            this.dataSource_chart = await DataHelper.InitAddinDownloadChart_datetime(parameters);
            this.dataSource = await DataHelper.InitAddinDownloadCount_date(parameters);
            for (var i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i]["rownum"] = tempRownum++;
            }
        },
        async nextDate() {
            const nextDate = dayjs(this.timeDate).add(1,'day').format('YYYY-MM-DD');
            let parameters = [];
            let param = {};
            let tempRownum = 1;
            this.timeDate = nextDate;
            param["START_DATE"] = '';
            param["END_DATE"] = '';
            param["YEAR"] = '';
            param["MONTH"] = '';
            param["TREND"] = '';
            param["DATE"] = this.timeDate;
            parameters.push(param);
            this.dataSource_chart = await DataHelper.InitAddinDownloadChart_datetime(parameters);
            this.dataSource = await DataHelper.InitAddinDownloadCount_date(parameters);
            for (var i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i]["rownum"] = tempRownum++;
            }
        },
        refresh() {
            let visible = '';
            
            this.itemSource.forEach(t => {
                console.log("t.visible : ",t.visible);
                if(t.visible == undefined) {
                    t.visible = false;
                }
                else {
                    t.visible = !t.visible;
                }
                visible = t.visible;
            });

            this.buttonText = visible == false ? "ON" : "OFF";
        }

    }
}
</script>

<style>
.graph_wrapper {
    border: solid 2px;
    border-color: #e0e0e0;
    width: auto;
    height: 450px;
    margin: 50px 0 20px 0
}
.bar_container {
    border: solid 2px;
    border-color: #e0e0e0;
    width:50%;
    height: 245px;
    margin: 8px 0 0 0
}
#bar_chart {
    height: 240px;
}
.trend_line {
    display: flex;
}
.time_calendar {
    display: flex;
}
</style>