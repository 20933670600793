<template>
    <div class="w-auto h-full bg-white px-6 py-11 font-Inter" id="AddinExecute_view">
        <div class="flex flex-row justify-between">
            <p class="text-[2rem] font-black font-Inter text-[#2B3133] tracking-tighter">애드인 실행 현황</p>
            <DxButton class="float-right"
              :width="130"
              text="Filter Reset"
              type="normal"
              styling-mode="contained"
              @click="refresh"
            />
        </div>
        
        <div class="h-full">
            <!-- <div class="flex pl-11 float-right">
                <select class="w-64 border-[1px] text-left pl-[1rem] h-[3rem] rounded-lg border-[#C7C7C7]"
                    v-model="term" @change="changeTerm($event)">
                    <option v-for="t in termList" :key="t">{{ t }}</option>
                </select>
                <div class="flex pl-8">
                    <input type="date" class="w-56 h-[3rem] pl-[20px] border-[1px] rounded-lg border-[#C7C7C7]"
                        :readonly="isReadOnly" v-model="startDate" @change="changeDate($event)">
                    <p class="px-8 pt-[5px] h-[3rem] align-middle">~</p>
                    <input type="date" class="w-56 h-[3rem] pl-[20px] border-[1px] rounded-lg border-[#C7C7C7]"
                        :readonly="isReadOnly" v-model="endDate">
                </div>
            </div> -->
            <div class="flex-col pt-[3rem]">
                <DxDataGrid id="OmGrid2" ref="dataGrid" :class="``" :height="`auto`" @initialized="saveGridInstance"
                    :allow-column-resizing="true" :column-resizing-mode="'currentMode'" :data-source="dataSource"
                    :show-borders="true">
                    <DxPaging :enabled="true" :page-size="20" />
                    <DxSelection mode="single" />
                    <DxEditing mode="popup" :allow-updating="false" :allow-adding="true" :allow-deleting="false">
                    </DxEditing>
                    <DxHeaderFilter :visible="true" />
                    <DxScrolling mode="'virtual" />
                    <DxColumn caption="번호" width="8%" data-field="rownum" alignment="center" />
                    <DxColumn caption="사용자명" width="12%" data-field="Name" alignment="center" />
                    <DxColumn caption="애드인명" width="20%" data-field="Title" alignment="center" />
                    <DxColumn caption="기능명" width="20%" data-field="CommandName" alignment="center" />
                    <DxColumn caption="애드인 버전" width="20%" data-field="AddinVersion" alignment="center" />
                    <DxColumn caption="실행시간" width="20%" data-field="ExecuteDate" alignment="center" />
                </DxDataGrid>
            </div>
        </div>
    </div>
</template>

<script>
import DxButton, { DxButtonTypes } from 'devextreme-vue/button';
import {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxEditing,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxForm,
    DxScrolling,
    DxToolbar,
    DxSelection,
    DxLoadPanel,
    DxKeyboardNavigation
} from 'devextreme-vue/data-grid';
import { DxPopup } from 'devextreme-vue/popup';
import {
    DxBarGauge, DxLabel, DxExport, DxTitle, DxFont, DxSize, DxMargin, DxLegend, DxTooltip
} from 'devextreme-vue/bar-gauge';
import DataHelper from '../Helpers/DataHelper';
export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxPopup,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxForm,
        DxScrolling,
        DxToolbar,
        DxSelection,
        DxLoadPanel,
        DxBarGauge, DxKeyboardNavigation,
        DxLabel, DxExport, DxTitle,
        DxFont, DxSize, DxMargin, DxLegend, DxTooltip,
        DxButton
    },
    props: {
        type: String,
    },
    computed: {
        dataGrid() {
            return this.$refs.dataGrid.instance;
        },
    },
    data() {
        return {
            yearList: [],
            year: null,
            s0List: [],
            s1List: [],
            dataSource: [],
            popupVisible: false,
            editTarget: {},
            formData: {},
            selectedRange: {},
            isSelectionStopped: true,
            cellInfos: [],
            data: [],
            isCtrlInput: false,
            bonbuList: [],
            preSumMap: new Map(), //예상 합계
            targetSumMap: new Map(), // 목표 합계
            textType: '1',
            termList: [],
            selected: '',
            isReadOnly: true,
            startDate: '',
            endDate: '',
            //parameters: [],
            test: {} ,
            today: new Date(),
            syear: "",
            smonth: "",
            sday: "",
            eyear: "",
            emonth: "",
            eday: "",
            tempDate: "",
            dataGridInstance: null
        }
    },
    watch: {
    },
    async mounted() {

        await this.initData();
    },
    methods: {
        async initData() {
            let parameters = [];
            this.dataSource = [];
            this.termList = ['지난 7일', '지난 28일', '지난 365일', '직접설정'];
            this.eyear = this.today.getFullYear();
            this.emonth = (this.today.getMonth() + 1).toString().padStart(2,'0');
            this.eday=this.today.getDate().toString().padStart(2, '0');
            this.endDate = this.eyear + '-' +this.emonth + '-' +this.eday;
            this.test['START_DATE'] = '2000-05-02';
            this.test['END_DATE'] = this.endDate;
            console.log(this.test);
            parameters.push(this.test);
            console.log(parameters);
            this.dataSource = await DataHelper.InitAddinExecute(parameters);
            this.dataGrid.repaint();
        },
        async changeTerm(event) {
            this.selected = event.target.value;
            this.isReadOnly = true;
            if (this.selected == this.termList[0]) { //지난 7일
                let parameters = [];
                this.tempDate = new Date(Date.parse(this.today) - 7 * 1000 * 60 * 60 * 24);
                this.syear = this.tempDate.getFullYear();
                this.smonth = (this.tempDate.getMonth() + 1).toString().padStart(2, '0');
                this.sday = this.tempDate.getDate().toString().padStart(2, '0');
                this.startDate = this.syear + '-' + this.smonth + '-' + this.sday;
                this.endDate = this.eyear + '-' +this.emonth + '-' +this.eday;
                this.test['START_DATE'] = this.startDate;
                this.test['END_DATE'] = this.endDate;
                parameters.push(this.test);
                this.dataSource = await DataHelper.InitAddinExecute(parameters);
                this.dataGrid.repaint();
            }
            else if (this.selected == this.termList[1]) { //지난 28일
                let parameters = [];
                this.tempDate = new Date(Date.parse(this.today) - 28 * 1000 * 60 * 60 * 24);
                this.syear = this.tempDate.getFullYear();
                this.smonth = (this.tempDate.getMonth() + 1).toString().padStart(2, '0');
                this.sday = this.tempDate.getDate().toString().padStart(2, '0');
                this.startDate = this.syear + '-' + this.smonth + '-' + this.sday;
                this.endDate = this.eyear + '-' +this.emonth + '-' +this.eday;
                this.test['START_DATE'] = this.startDate;
                this.test['END_DATE'] = this.endDate;
                parameters.push(this.test);
                this.dataSource = await DataHelper.InitAddinExecute(parameters);
                this.dataGrid.repaint();
            }
            else if (this.selected == this.termList[2]) { //지난 365일
                let parameters = [];
                this.tempDate = new Date(Date.parse(this.today) - 365 * 1000 * 60 * 60 * 24);
                this.syear = this.tempDate.getFullYear();
                this.smonth = (this.tempDate.getMonth() + 1).toString().padStart(2, '0');
                this.sday = this.tempDate.getDate().toString().padStart(2, '0');
                this.startDate = this.syear + '-' + this.smonth + '-' + this.sday;
                this.endDate = this.eyear + '-' +this.emonth + '-' +this.eday;
                this.test['START_DATE'] = this.startDate;
                this.test['END_DATE'] = this.endDate;
                parameters.push(this.test);
                this.dataSource = await DataHelper.InitAddinExecute(parameters);
                this.dataGrid.repaint();
            }
            else { //직접설정
                this.isReadOnly = false;
            }
        },
        saveGridInstance(e) {
            this.dataGridInstance = e.component;
        },
        refresh() {
            this.dataGrid.clearSorting();
            this.dataGrid.clearFilter();
        }
    }
}
</script>

<style>
#OmGrid2 .dx-toolbar-items-container {
    height: 0.8rem;
}

#OmGrid2 .dx-item-content .dx-button-content {
    visibility: hidden;
    display: none;
}

#OmGrid2 .dx-item-content .dx-widget {
    visibility: hidden;
    display: none;
}

#OmGrid2 td {
    padding: 6px 5px;
    border: 1px solid #e0e0e0 !important;
}

#OmGrid2 .dx-header-row {
    height: 30px;
    text-align: center;
    width: auto;
    padding-left: 8px;
    font-size: 1.2rem;
}

#OmGrid2 .dx-master-detail-cell {
    padding: 0px;
}
#OmGrid2 .dx-pager .dx-pages  {
    display: flex !important;
    justify-content: center !important;
    float: unset;
}
</style>